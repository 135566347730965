<template>
  <CabinetPage :preloader="preloader">

    <div class="mx-cabinet-page-header">
      <h4 class="mx-title">{{ $t('document.new_document') }}</h4>
      <a href="#" class="btn btn-peepz-default" @click.prevent="DeleteDocumentModal(true)">
        <ph-trash-simple :size="22" />
      </a>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="mx-form-header"></div>
        <form class="mx-form">
          <div class="mx-modal-form">

           <div class="row">
             <div class="col-md-6">
               <div class="mx-modal-form__element">
                 <label class="form-label">{{ $t(`company.person.form.birthday`) }}</label>
                 <input type="text" class="form-control"  v-model="form.name">
                 <div v-if="errors['name']" class="invalid-feedback">
                   {{ errors['name'][0] }}
                 </div>
               </div>
             </div>
             <div class="col-md-6">
               <div class="mx-modal-form__element">
                 <label class="form-label">Kategorie</label>
                 <b-form-select
                     v-model="form.category_id"
                     :options="categories"
                     class="form-control"
                     value-field="id"
                     text-field="name"
                 ></b-form-select>
               </div>
             </div>
           </div>
          </div>

          <div class="mx-form-buttons">
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">
              {{ $t('company.person.csv.load') }}
            </button>
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'documents'}" class="btn btn-peepz-default">
              <span>{{ $t('document.btn.abort') }}</span>
            </router-link>
          </div>
        </form>
      </div>
    </div>

    <!--  Modal  -->
    <b-modal ref="DeleteCompany" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="mx-modal-prompt">
        <h3 class="mx-modal-prompt__title" style="text-align: center">{{ $t('document.prompt.delete.title') }}</h3>
        <strong class="mx-modal-prompt__subtitle">{{ $t('document.prompt.delete.subtitle') }}</strong>
        <div class="mx-modal-prompt__buttons">
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-danger" @click="documentDelete">{{ $t('company.delete') }}</button>
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="DeleteDocumentModal(false)">{{ $t('company.abort') }}</button>
        </div>
      </div>
    </b-modal>

  </CabinetPage>
</template>

<script>
import CabinetPage from "@/components/main/CabinetPage";
import {PhTrashSimple} from "phosphor-vue";

export default {
  name: "DocumentShowView",
  components: {CabinetPage, PhTrashSimple},
  data() {
    return {
      preloader: true,
      form: {
        id: null,
        name: '',
        employees: [],
        customers: [],
        teams: [],
        roles: [],
        for: 'employee',
        for_all: 0,
        category_id: 1
      },
      button_disabled: false,
      errors: [],
      categories: [],
    }
  },
  created() {
    this.getDocuments()
  },
  methods: {
    DeleteDocumentModal(boll) {
      if (boll) {
        this.$refs['DeleteCompany'].show()
      } else {
        this.$refs['DeleteCompany'].hide()
      }
    },
    documentDelete() {
      this.button_disabled = true
      this.$store.dispatch('documentDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'documents'
        })
      }).catch(() => {
        this.button_disabled = false
      })
    },
    getDocuments() {
      this.$store.dispatch('documentCategories').then(() => {
        this.categories = this.$store.getters.document_categories

        this.$store.dispatch('documentEdit', this.$route.params.id).then(() => {
          let document = this.$store.getters.document
          this.form.id = this.$route.params.id
          this.form.name = document.name
          this.form.category_id = document.category_id
          this.form.employees = document.employees
          this.preloader = false
        })

      })
    },
    save() {
      this.$store.dispatch('documentUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'documents'
        })
      }).catch(error => {
        this.button_disabled = true;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
          case 500:
            this.errors.push({'status': 500, 'text': 'Server error'})
            break;
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
